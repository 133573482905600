import { Box, Button, RangeInput } from "grommet"
import { forwardRef, useCallback, useEffect, useLayoutEffect, useState } from "react"
import { Pause, Play } from "grommet-icons";

export const VideoPreview = ({ src, hidePlayer = false, roundCorners = false, onEnded, ...props }) => {

    const [videoNode, setVideoNode] = useState();
    const [isPlaying, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const videoRef = useCallback((node) => {
        if (!node) return;

        setVideoNode(node);
    }, [])

    useLayoutEffect(() => {
        return () => {
            if (isPlaying) {
                videoNode?.pause();
                setPlaying(false);
            }
        }
    }, [videoNode, isPlaying])

    const onVideoPlay = () => {
        console.log('play');
        setPlaying(true);
    }

    const onVideoReady = (event) => {
        console.log('ready');

        const video = event.target;

        setDuration(video.duration);

        if (!isPlaying) {
            console.log('play');
            
            videoNode.play().then(_=> setPlaying(true));
        }
    }

    const handleMoveTimeline = (event) => {
        videoNode.currentTime = event.target.value;
    }

    const handleTimelinePause = () => {
        videoNode.pause();
    }

    const handleTimelinePlay = () => {
        videoNode.play();
    }

    const onVideoTimeChange = () => {
        setCurrentTime(videoNode.currentTime);
    }

    const handleVideoPlay = () => {
        if (!isPlaying) {
            videoNode.play();
        }
        if (isPlaying) {
            videoNode.pause();
        }
    }

    const onVideoPause = () => {
        setPlaying(false);
    }

    const onVideoEnded = () => {
        if (onEnded) {
            onEnded();
        }
    }

    return (
        <Box style={{ display: src ? 'flex' : 'none' }}>
            <Box direction="column" className={roundCorners ? 'video-preview' : ''} style={{ position: 'relative' }}>
                <VideoPlayer 
                    src={src} 
                    onPlay={onVideoPlay} 
                    onLoadedData={onVideoReady}
                    onPause={onVideoPause}
                    onEnded={onVideoEnded}
                    onTimeUpdate={onVideoTimeChange} 
                    ref={videoRef}
                    autoPlay
                    volume={0.2}
                    // muted
                />
            </Box>
            {!hidePlayer && <Box direction="row" gap="xsmall" align="center">
                <Button icon={isPlaying ? <Pause/> : <Play/>} onClick={handleVideoPlay} />
                <RangeInput 
                    value={currentTime} 
                    min={0} 
                    max={duration} 
                    step={0.001} 
                    onMouseDown={handleTimelinePause} 
                    onMouseUp={handleTimelinePlay} 
                    onChange={handleMoveTimeline} 
                />
            </Box>}
        </Box>
    )
}

const VideoPlayer = forwardRef(({ src, ...props }, ref) => {

    return (
        <video 
            ref={ref}
            playsInline
            height={'100%'}
            width={'100%'}
            src={src}
            crossOrigin="anonymous"
            {...props}
        >
        </video>
    )
})

