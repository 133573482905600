import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const eventRecordsApi = createApi({
    reducerPath: 'eventRecordsApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/eventrecords`, credentials: 'include' }),
    tagTypes: ['EventRecords'],
    endpoints: (builder) => ({
        getEventRecords: builder.query({
            query: (args) => {
                const { page, pageSize, sortBy } = args;
                return {
                    url: '',
                    params: {
                        page,
                        pageSize,
                        sortBy
                    } 
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['EventRecords']
        }),
        replayInteraction: builder.mutation({
            query: (args) => {
                return {
                    url: `/${args.eventId}`,
                    method: 'POST'
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response
        }),
    }),
})

export const { useLazyGetEventRecordsQuery, useReplayInteractionMutation } = eventRecordsApi