import { DndContext } from "@dnd-kit/core"
import { Box, Button, RangeInput, Text } from "grommet"
import { Money } from "grommet-icons"
import { DraggableMemePreview } from "./DraggableMemePreview"
import { DroppableStreamPreview } from "./DroppableStreamPreview"
import { createContext, useState } from "react"
import { useSendInteractionMutation } from "../../api/interactionsApi"
import { useParams } from "react-router-dom"
import { add } from "mathjs"
import { formattedPrice } from "../../utils/priceUtils"
import { useDispatch } from "react-redux"
import { addNotification } from "../../slices/notificationsSlice"

export const VideoSizeContext = createContext();

export const SpotMemeWindow = ({ meme, priceConfig, onClose }) => {

    const dispatch = useDispatch();
    const params = useParams();
    const [memeCoordinates, setMemeCoordinates] = useState({ x: 0, y: 0 });
    const [memeSize, setMemeSize] = useState(0.5);
    const [memeVolume, setMemeVolume] = useState(0.01);
    const [sendInteraction, { isLoading: isSending }] = useSendInteractionMutation();

    const handleOnDragEnd = (event) => {
        console.log(event);
        
        const size = event.active.data.current.size;

        // const rect = event.over.rect;
        // const areaSize = { height: rect.height, width: rect.width };
        
        const x = add(memeCoordinates.x, event.delta.x);
        const y = add(memeCoordinates.y, event.delta.y);
        
        console.log(`${x}:${y}`);
        // console.log(areaSize);
        console.log(size);
        
        
        
        setMemeCoordinates({ x, y, ...size });
    }

    const handleVideoSize = (size) => {
        setMemeCoordinates(prevValue => ({ ...prevValue, ...size }));
    }

    const handleSendSpotInteraction = () => {
        console.log(memeCoordinates);
        
        sendInteraction({ 
            interactionId: meme.id,
            recipient: params.username,
            position: memeCoordinates
        })
        .unwrap()
        .then(() => {
            onClose();
            dispatch(addNotification({ show: true, message: 'Sent interaction successfully.', status: 'normal' }));
        })
        .catch((errorResponse) => {
            console.log(errorResponse);
            if (errorResponse?.data?.length > 0) {
                const errors = errorResponse?.data;
                errors.forEach((error) => dispatch(addNotification({ show: true, message: error?.message, status: 'critical' })))
            } else {
                dispatch(addNotification({ show: true, message: errorResponse?.data?.message, status: 'critical' }))
            }
        });
    }

    return (
        <Box direction="column" gap="medium">
            <Box>
                <VideoSizeContext.Provider value={handleVideoSize}>
                    <DndContext onDragEnd={handleOnDragEnd} /*modifiers={[restrictToParentElement]}*/>
                        <Box style={{ position: 'relative', overflow: 'hidden' }} height={`${248 * 1.5}px`} width={`${440 * 1.5}px`} background={`url(https://static-cdn.jtvnw.net/previews-ttv/live_user_${params.username}-440x248.jpg)`}>
                            <DraggableMemePreview 
                                volume={memeVolume} 
                                size={memeSize}
                                meme={meme}
                                styles={{ position: 'absolute', left: `${memeCoordinates.x}px`, top: `${memeCoordinates.y}px` }} 
                            />
                            <DroppableStreamPreview />
                        </Box>
                    </DndContext>
                </VideoSizeContext.Provider>
            </Box>
            <Box>
                <Box fill="horizontal" margin={{top: 'small'}}>
                    <Text weight={500} size="14px">Size</Text>
                    <RangeInput value={memeSize} onChange={(event) => setMemeSize(Number(event.target.value))} min={0.1} max={1} step={0.01}/>
                </Box>
                <Box fill="horizontal" margin={{top: 'small'}}>
                    <Text weight={500} size="14px">Volume</Text>
                    <RangeInput value={memeVolume} onChange={(event) => setMemeVolume(Number(event.target.value))} min={0} max={1} step={0.01}/>
                </Box>
            </Box>
            <Button label={`Confirm and send ${formattedPrice(priceConfig.price)}`} icon={<Money/>} busy={isSending} primary reverse onClick={handleSendSpotInteraction}/>
        </Box>
    )
}