import { createSlice } from "@reduxjs/toolkit";

export const modalWindowsSlice = createSlice({
    name: 'modalWindows',
    initialState: {
        memeModal: {
            show: false,
            meme: null,
        }
    },
    reducers: {
        setMemeModal: (state, action) => {
            state.memeModal = action.payload;
        }
    }
})

export const { setMemeModal } = modalWindowsSlice.actions;

export default modalWindowsSlice.reducer;