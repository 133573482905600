import { useDroppable } from "@dnd-kit/core";
import { Box } from "grommet";

export const DroppableStreamPreview = () => {

    const {isOver, setNodeRef} = useDroppable({
        id: `droppable-stream-preview`,
        data: {}
    });

    return (
        <Box ref={setNodeRef} key={'stream-preview'} fill></Box>
    )
}