import { BoxWindow } from "../components/BoxWindow"
import { Box, Button, Card, CardBody, Notification, SelectMultiple, Spinner, Tab, Tabs, Text, TextInput } from "grommet";
import { Add, Search } from "grommet-icons";
import { useCallback, useEffect, useState } from "react";
import { MemesGrid } from "../components/memes/MemesGrid";
import { MemesTags } from "../components/memes/MemesTags";
import { MemeCreateDialog } from "../components/memes/dialog/MemeCreateDialog";
import { useGetInteractionsQuery } from "../api/interactionsApi";
import { useOnWindowScroll } from "rooks";
import { useGetCurrentUserQuery, useGetFavoriteInteractionsQuery } from "../api/userApi";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../slices/authSlice";
import { TwitchAuth } from "../components/auth/TwitchAuth";
import MemesGlobalList from "../components/memes/MemesGlobalList";
import MemesFavoritesList from "../components/memes/MemesFavoritesList";
import { useGetConfigsQuery } from "../api/configApi";
import { useTranslation } from "react-i18next";

// const memes = [
//     { id: 1, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { id: 2, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
//     { id: 3, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
//     { id: 4, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
//     { id: 5, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
//     { id: 6, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
//     { id: 7, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
//     { id: 8, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
//     { id: 9, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { id: 10, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
//     { id: 11, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
//     { id: 12, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
//     { id: 13, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
//     { id: 14, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' }
// ]

const popular = [
    { title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
]

// const memesFavorites = [
//     { title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
//     { title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
//     { title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
//     { title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
// ]

const tags = [
    '#cats',
    "#jumpscares",
    "#troll",
    "#overlay",
    "#kekw",
    "#loading",
    "#trump",
    "#meme",
    "#anime",
    "#XQC",
    "#politic",
    "#hot",
    "#tvshows",
    "#hot",
    "#tvshows",
    "#politic",
    "#hot",
    "#tvshows",
]

export const MemesPage = () => {

    const { t, ready } = useTranslation('memes', {
		useSuspense: false,
	});
    const isAuthenticated = useSelector(authenticationSelector);
    const [searchTerm, setSearchTerm] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [configSet, setConfigSet] = useState({});
    const { data: configs } = useGetConfigsQuery();

    useEffect(() => {
        setConfigSet(configs?.reduce((accum, config) => ({ ...accum, [config.configKey]: config.value }), {}));
    }, [configs])

    if (!ready) {
        return (<Spinner/>)
    }

    return (
        <Box fill>
            <Notification title={<Text size="xsmall">{t('betaTestBanner')}</Text>} status="warning" global />
            <BoxWindow margin={{ top: 'medium' }}>
                <Card background="#222222">
                    <CardBody pad="medium">
                        <Box direction="row" gap="small" align="center">
                            <Box flex>
                                <TextInput name="_search" icon={<Search/>} type="search" onChange={(event) => setSearchTerm(event.target.value)}/>
                            </Box>
                            <Box direction="row" gap="small" align="center">
                                {/* <SelectMultiple placeholder="Select filter" options={['Fullscreen memes', 'Spot memes', 'Mixed memes']} /> */}
                                {isAuthenticated && <Button label={t('memeCreateButton')} icon={<Add/>} onClick={() => setShowCreateDialog(true)} disabled={configSet && configSet['interactions.upload.enabled'] === "false"} />}
                                {!isAuthenticated && <TwitchAuth />}
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </BoxWindow>
            {/* <BoxWindow bottomPadding={false}>
                <MemesTags tags={tags} />
            </BoxWindow> */}
            <BoxWindow>
                <Tabs alignControls="end" onActive={setActiveTab}>
                    <Tab title={t('tabMemesTitle')}>
                        <MemesGlobalList searchTerm={searchTerm} activeTab={activeTab} />
                    </Tab>
                    {isAuthenticated && <Tab title={t('tabFavoritesTitle')}>
                        <MemesFavoritesList searchTerm={searchTerm} activeTab={activeTab} />
                    </Tab>}
                </Tabs>
            </BoxWindow>
            <MemeCreateDialog show={showCreateDialog} onClose={() => setShowCreateDialog(false)} />
        </Box>
    )
}