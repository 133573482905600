import { Box, Card, CardBody, Grid, Image, InfiniteScroll, Stack, Text } from "grommet"
import { createContext, memo, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import './MemesGrid.css'
import { MemeHoverContext } from "./MemesGrid";
import { MemeDialog } from "./dialog/MemeDialog";
import { useDispatch } from "react-redux";
import { setMemeModal } from "../../slices/modalWindowsSlice";
import { useOnWindowResize, useOnWindowScroll } from "rooks";
import { MemePreviewTitle } from "./MemePreviewTitle";

const MemeWithChromaKeyPreview = ({ meme, ...props }) => {

    const dispatch = useDispatch();

    const { handleHoverEvent, handlePosition } = useContext(MemeHoverContext);

    const [hide, setHide] = useState();

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const [topCoord, setTopCoord] = useState();
    const [leftCoord, setLeftCoord] = useState();
    const [moveTo, setMoveTo] = useState();
    const [size, setSize] = useState();

    const [videoNode, setVideoNode] = useState();

    const videoRef = useRef();

    const handleMouseEnter = () => {
        setHide(true);
    }

    const handleMouseLeave = () => {
        setHide(false);
    }

    const handleOpenDialog = () => {
        setHide(false);
        dispatch(setMemeModal({ show: true, meme: meme }));
    }

    const measuredRef = useCallback(node => {
        setVideoNode(node);
    }, []);

    useEffect(() => {
        if (hide && moveTo && size && meme) {
            handleHoverEvent('mouseenter', moveTo, size, meme, videoRef.current);
        } else if (hide === false && moveTo && size && meme) {
            handleHoverEvent('mouseleave', moveTo, size, meme, videoRef.current);
        }
    }, [meme, moveTo, size, hide])

    useEffect(() => {
        setSize({ height: height, width: width });
    }, [height, width])

    useEffect(() => {
        setMoveTo({ top: topCoord, left: leftCoord });
    }, [topCoord, leftCoord])

    useLayoutEffect(() => {
        if (videoNode) {
            const resizeObserver = new ResizeObserver(() => {
                const rect = videoNode.getBoundingClientRect();
                
                setTopCoord(rect.top + window.scrollY);
                setLeftCoord(rect.left + window.scrollX);
    
                setHeight(rect.height);
                setWidth(rect.width);
            });
            resizeObserver.observe(videoNode);

            return () => resizeObserver.unobserve(videoNode);
        }
    }, [videoNode])

    // useOnWindowResize(() => {
    //     if (videoNode) {
    //         const rect = videoNode.getBoundingClientRect();
                        
    //         setTopCoord(rect.top + window.scrollY);
    //         setLeftCoord(rect.left + window.scrollX);

    //         setHeight(rect.height);
    //         setWidth(rect.width);
    //     }
    // }, [videoNode], true);

    useOnWindowScroll(() => {
        if (videoNode) {
            const rect = videoNode.getBoundingClientRect();
                        
            setTopCoord(rect.top + window.scrollY);
            setLeftCoord(rect.left + window.scrollX);

            setHeight(rect.height);
            setWidth(rect.width);
        }
    }, [videoNode], true);

    useLayoutEffect(() => {
        handlePosition(meme.id, { top: topCoord, left: leftCoord }, { height: height, width: width });
    }, [topCoord, leftCoord, height, width])

    return (
        <Box key={meme.id} {...props} onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave} onClick={handleOpenDialog} focusIndicator={false}>
            <Card background="#222222" /*hoverIndicator={{
                background: {
                color: 'background-contrast',
                },
                elevation: 'medium',
            }}*/>
                <CardBody margin={'small'} pad={'none'}>
                    <Stack anchor="bottom-left">
                        <Box style={{ position: 'relative', visibility: hide ? 'hidden' : 'visible', height: '100%', width: '100%' }} className="meme-preview">
                            <Box style={{ height: '100%', width: '100%' }}>
                                <Image height={'100%'} width={'100%'} fit="contain" src={meme.interactionPreview} />
                            </Box>
                            <Box ref={measuredRef} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', visibility: 'hidden' }}>
                                <video key={meme.id} myId={meme.id} ref={videoRef} playsInline muted height={'100%'} width={'100%'} src={`${meme.interactionVideo}?app=whatyouwish`} crossOrigin="anonymous"></video>
                            </Box>
                        </Box>
                        <MemePreviewTitle meme={meme} style={{ position: 'relative', zIndex: 2, pointerEvents: 'none', display: hide ? 'flex' : 'none' }}  />
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    )
}

export default memo(MemeWithChromaKeyPreview);