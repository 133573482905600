import { useParams } from "react-router-dom";
import { BoxWindow } from "../components/BoxWindow"
import { Box, Button, Card, CardBody, SelectMultiple, Spinner, Tab, Tabs, Text, TextInput } from "grommet";
import { Add, Alert, Search } from "grommet-icons";
import { useCallback, useEffect, useState } from "react";
import { MemesGrid } from "../components/memes/MemesGrid";
import { MemesUserHeader } from "../components/memes/MemesUserHeader";
import { MemesTags } from "../components/memes/MemesTags";
import { MemeCreateDialog } from "../components/memes/dialog/MemeCreateDialog";
import { useGetInteractionSettingsQuery, useGetInteractionsQuery } from "../api/interactionsApi";
import { useGetCurrentUserQuery, useGetFavoriteInteractionsQuery } from "../api/userApi";
import { authenticationSelector } from "../slices/authSlice";
import { useSelector } from "react-redux";
import { TwitchAuth } from "../components/auth/TwitchAuth";
import MemesGlobalList from "../components/memes/MemesGlobalList";
import MemesFavoritesList from "../components/memes/MemesFavoritesList";
import { useGetConfigsQuery } from "../api/configApi";
import { useTranslation } from "react-i18next";

const memesData = [
    { id: 1, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 2, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 3, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 4, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 5, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 6, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 7, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 8, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 9, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 10, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 11, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 12, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 13, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 14, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 15, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 16, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 17, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 18, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 19, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 20, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 21, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 22, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 23, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 24, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 25, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 26, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 27, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 28, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 29, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 30, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 31, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 32, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 33, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 34, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 35, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 36, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 37, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 38, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 39, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 40, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 41, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 42, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 43, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 44, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 45, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 46, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 47, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 48, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 49, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 50, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 51, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 52, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 53, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 54, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 55, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 56, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 57, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 58, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 59, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 60, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 61, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 62, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 63, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 64, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 65, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 66, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 67, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 68, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 69, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 70, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 71, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 72, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 73, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 74, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 75, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 76, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 77, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 78, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 79, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 80, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 81, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 82, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 83, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 84, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 85, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 86, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 87, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 88, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 89, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 90, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 91, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 92, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 93, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 94, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 95, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 96, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 97, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 98, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
    { id: 99, title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 100, title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 101, title: 'Some title3', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 102, title: 'Some title4', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 103, title: 'Some title5', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 104, title: 'Some title6', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 105, title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { id: 106, title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 107, title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { id: 108, title: 'Some title10', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
    { id: 109, title: 'Some title11', w: 272, h: 272, isMixed: true, url: 'https://cdns.memealerts.com/p/644158a73d5b79b4cbea6378/1e24ff70-de8c-4a5d-be07-76006e82e889/alert_orig.webm' },
    { id: 110, title: 'Some title12', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
    { id: 111, title: 'Some title13', w: 788, h: 788, isSpot: true, url: 'https://assets.tangia.co/actions/Breaking News2_e56b744d07ff.webm' },
    { id: 112, title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
]

const popular = [
    { title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
    { title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
    { title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
]

// const memesFavorites = [
//     { title: 'Some title7', w: 470, h: 466, isMixed: true, url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' },
//     { title: 'Some title1', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { title: 'Some title2', w: 720, h: 480, isFullscreen: true, url: 'https://assets.tangia.co/actions/DaffyDuck480.ebm_0a8c9b45b341.webm' },
//     { title: 'Some title9', w: 468, h: 708, isMixed: true, url: 'https://cdns.memealerts.com/p/633e0485678ff4dbe542f5ac/6ead9a13-8d0a-4622-84aa-70f4d871f23d/alert_orig.webm' },
//     { title: 'Some title14', w: 781, h: 781, isSpot: true, url: 'https://assets.tangia.co/actions/HatInteraction_ee8295f1eb7c.webm' },
//     { title: 'Some title8', w: 472, h: 262, isMixed: true, url: 'https://cdns.memealerts.com/p/651c792f22708f0430f7a2ae/d311112e-4eae-450e-9427-41c57f1a02aa/alert_orig.webm' },
// ]

const tags = [
    '#cats',
    "#jumpscares",
    "#troll",
    "#overlay",
    "#kekw",
    "#loading",
    "#trump",
    "#meme",
    "#anime",
    "#XQC",
    "#politic",
    "#hot",
    "#tvshows",
    "#hot",
    "#tvshows",
    "#politic",
    "#hot",
    "#tvshows",
]

const generateMemes = (count) => {
    return Array(count).fill(0).map((value, index) => ({ id: index + 1, isMixed: true, title: 'Some title ' + (index + 1), url: 'https://cdns.memealerts.com/p/643d6c10de7e288924704dd7/7e9c4099-259b-477f-b7a5-0d5b96215575/alert_orig.webm' }))
}

export const DonatePage = () => {

    const { t, ready } = useTranslation('memes', {
		useSuspense: false,
	});
    const params = useParams();
    const isAuthenticated = useSelector(authenticationSelector);
    const [searchTerm, setSearchTerm] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [configSet, setConfigSet] = useState({});
    const { data: configs } = useGetConfigsQuery();
    const { data: interactionSettings, isLoading: isInteractionSettingsLoading, isSuccess: isInteractionSettingsLoaded, isError: isInteractionSettingsError } = useGetInteractionSettingsQuery(params.username, { skip: !isAuthenticated })

    useEffect(() => {
        setConfigSet(configs?.reduce((accum, config) => ({ ...accum, [config.configKey]: config.value }), {}));
    }, [configs])

    if (!ready) {
        return (<Spinner/>)
    }


    if (isInteractionSettingsLoading) {
        return (
            <Box>Loading...</Box>
        )
    }

    if (isInteractionSettingsError) {
        return (
            <BoxWindow>
                <Card background="#222222" height={'100vh'} margin={{ top: 'medium' }}>
                    <CardBody pad="medium" align="center" justify="center">
                        <Alert size="xlarge" /><Text size="xlarge">{t('userNotFound')}</Text>
                    </CardBody>
                </Card>
            </BoxWindow>
        )
    }

    return (
        <Box>
            <BoxWindow>
                {isInteractionSettingsLoaded && <MemesUserHeader username={params.username} monobankJarUrl={interactionSettings.monobankJarUrl} />}
            </BoxWindow>
            <BoxWindow>
                <Card background="#222222" fill>
                    <CardBody pad="medium">
                        <Box direction="row" gap="small" align="center">
                            <Box flex>
                                <TextInput name="_search" icon={<Search/>} type="search" onChange={(event) => setSearchTerm(event.target.value)}/>
                            </Box>
                            <Box direction="row" gap="small" align="center">
                                {/* <SelectMultiple placeholder="Select filter" options={['Fullscreen memes', 'Spot memes', 'Mixed memes']} /> */}
                                {isAuthenticated && <Button label={t('memeCreateButton')} icon={<Add/>} onClick={() => setShowCreateDialog(true)} disabled={configSet && configSet['interactions.upload.enabled'] === "false"} />}
                                {!isAuthenticated && <TwitchAuth />}
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </BoxWindow>
            {/* <BoxWindow bottomPadding={false}>
                <MemesTags tags={tags} />
            </BoxWindow> */}
            <BoxWindow>
                <Tabs alignControls="end" onActive={setActiveTab}>
                    <Tab title={t('tabMemesTitle')}>
                        <MemesGlobalList interactionSettings={interactionSettings} searchTerm={searchTerm} activeTab={activeTab} isDonatePage />
                    </Tab>
                    {/* <Tab title={`${params.username}'s popular`}>
                        <MemesGrid memes={popular} isDonatePage />
                    </Tab> */}
                    {isAuthenticated && <Tab title={t('tabFavoritesTitle')}>
                        <MemesFavoritesList interactionSettings={interactionSettings} searchTerm={searchTerm} activeTab={activeTab} isDonatePage />
                    </Tab>}
                </Tabs>
            </BoxWindow>
            <MemeCreateDialog show={showCreateDialog} onClose={() => setShowCreateDialog(false)} />
        </Box>
    )
}