import { Box, Card, CardBody, Grid, Image, InfiniteScroll, Stack, Text } from "grommet"
import { createContext, memo, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import './MemesGrid.css'
import { MemeHoverContext } from "./MemesGrid";
import { MemeDialog } from "./dialog/MemeDialog";
import { useDispatch } from "react-redux";
import { setMemeModal } from "../../slices/modalWindowsSlice";
import { useOnWindowResize, useOnWindowScroll } from "rooks";
import { MemePreviewTitle } from "./MemePreviewTitle";

const MemePreview = ({ meme, ...props }) => {

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleMouseHover = () => {
        setShow(true);
        // videoRef.current.play();
    }

    const handleMouseLeave = () => {
        setShow(false);
        // videoRef.current.pause();
        // videoRef.current.currentTime = 0;
    }

    const handleOpenDialog = () => {
        dispatch(setMemeModal({ show: true, meme: meme }));
    }

    return (
        <Box key={meme.id} {...props} onPointerEnter={handleMouseHover} onPointerLeave={handleMouseLeave} onClick={handleOpenDialog}>
            <Card background="#222222" /*hoverIndicator={{
                background: {
                color: 'background-contrast',
                },
                elevation: 'medium',
            }}*/>
                <CardBody margin={'small'} pad={'none'}>
                    <Stack anchor="bottom-left">
                        <Box style={{ position: 'relative', height: '100%', width: '100%' }} className="meme-preview">
                            <Box>
                                <Image height={'100%'} width={'100%'} fit="contain" src={meme.interactionPreview} />
                            </Box>
                            {show && <Box style={{ position: 'absolute', top: 0, left: 0 }}>
                                <video key={meme.id} myId={meme.id} autoPlay playsInline muted height={'100%'} width={'100%'} src={`${meme.interactionVideo}?app=whatyouwish`} crossOrigin="anonymous"></video>
                            </Box>}
                        </Box>
                        <MemePreviewTitle meme={meme} style={{ position: 'relative', zIndex: 2, pointerEvents: 'none', display: show ? 'flex' : 'none' }}  />
                    </Stack>
                </CardBody>
            </Card>
        </Box>
    )
}

export default memo(MemePreview);