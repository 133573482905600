import { configureStore } from '@reduxjs/toolkit'

import timerReducer from '../slices/timerSlice'
import donatesReducer from '../slices/donatesSlice'
import socketReducer from '../slices/socketReducer'
import { donatesApi } from '../api/donatesApi'
import { authApi } from '../api/authApi'
import { userApi } from '../api/userApi'
import authReducer from '../slices/authSlice'
import rouletteReducer from '../slices/rouletteSlice'
import { rouletteWidgetApi } from '../api/rouletteWidgetApi'
import { fileApi } from '../api/fileApi'
import { emotesApi } from '../api/emotesApi'
import auctionDataReducer from '../slices/auctionDataSlice'
import wheelSettingsReducer from '../slices/wheelSettingsSlice'
import wheelDataReducer from '../slices/wheelDataSlice'
import { localStoragePersistanceMiddleware } from './localStoragePersistanceMiddleware'
import { calculateWheelDataMiddleware } from './calculateWheelDataMiddleware'
import { setWheelSpinModeMiddleware } from './wheelSpinModeMiddleware'
import { copyWheelDataMiddleware } from './copyWheelDataMiddleware'
import { calculateDonateSuggestionsMiddleware } from './calculateDonateSuggestionsMiddleware'
import { persistanceMiddleware } from './persistanceMiddleware'
import { calculateChancesMiddleware } from './calculateChancesMiddleware'
import { sortItemsMiddleware } from './sortItemsMiddleware'
import { interactionsApi } from '../api/interactionsApi'
import notificationsReducer from '../slices/notificationsSlice'
import webGlContextReducer from '../slices/webGlContextSlice'
import modalWindowsReducer from '../slices/modalWindowsSlice'
import { monobankApi } from '../api/monobankApi'
import { donateRecordsApi } from '../api/donateRecordsApi'
import { eventRecordsApi } from '../api/eventRecordsApi'
import { configApi } from '../api/configApi'

export default configureStore({
  reducer: {
    auctionData: auctionDataReducer,
    wheelSettings: wheelSettingsReducer,
    wheelData: wheelDataReducer,
    timer: timerReducer,
    donates: donatesReducer,
    ws: socketReducer,
    auth: authReducer,
    roulette: rouletteReducer,
    notifications: notificationsReducer,
    webGlContext: webGlContextReducer,
    modalWindows: modalWindowsReducer,
    [donatesApi.reducerPath]: donatesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [rouletteWidgetApi.reducerPath]: rouletteWidgetApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [emotesApi.reducerPath]: emotesApi.reducer,
    [interactionsApi.reducerPath]: interactionsApi.reducer,
    [monobankApi.reducerPath]: monobankApi.reducer,
    [donateRecordsApi.reducerPath]: donateRecordsApi.reducer,
    [eventRecordsApi.reducerPath]: eventRecordsApi.reducer,
    [configApi.reducerPath]: configApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
    .concat(donatesApi.middleware)
    .concat(authApi.middleware)
    .concat(userApi.middleware)
    .concat(rouletteWidgetApi.middleware)
    .concat(fileApi.middleware)
    .concat(emotesApi.middleware)
    .concat(interactionsApi.middleware)
    .concat(monobankApi.middleware)
    .concat(donateRecordsApi.middleware)
    .concat(eventRecordsApi.middleware)
    .concat(configApi.middleware)
    .concat(sortItemsMiddleware)
    .concat(calculateChancesMiddleware)
    .concat(calculateDonateSuggestionsMiddleware)
    .concat(copyWheelDataMiddleware)
    .concat(setWheelSpinModeMiddleware)
    .concat(calculateWheelDataMiddleware)
    .concat(persistanceMiddleware)
    .concat(localStoragePersistanceMiddleware),
  devTools: true
})