import { useSelector } from "react-redux";
import { authenticationSelector } from "../../slices/authSlice";
import { Navigate } from "react-router-dom";


export const AuthenticatedRoute = ({ children }) => {

    const isAuthenticated = useSelector(authenticationSelector);

    if (!isAuthenticated) {
        return (
            <Navigate to="/" replace />
        )
    }
    return children;
}