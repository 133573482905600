import { Box, Button, Card, CardBody, List, Spinner } from "grommet"
import { Outlet, useNavigate } from "react-router-dom";
import { BoxWindow } from "../components/BoxWindow";
import { Currency, Plan } from "grommet-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const DashboardPage = () => {

    const { t, i18n, ready } = useTranslation('dashboard', { useSuspense: false });
    const [menuItems] = useState([
        { title: { en: t('dashboard:eventsTitle', { lng: 'en' }), uk: t('dashboard:eventsTitle', { lng: 'uk' }) }, icon: <Plan />, link: '/dashboard/events' },
        { title: { en: t('dashboard:paymentsHistoryTitle', { lng: 'en' }), uk: t('dashboard:paymentsHistoryTitle', { lng: 'uk' }) }, icon: <Currency />, link: '/dashboard/payments-history' },
    ]);
    const navigate = useNavigate();

    if (!ready) {
        return <Spinner/>
    }

    return (
        <Box height={'100vh'}>
            <BoxWindow topPadding>
                <Box direction="row" gap={'medium'} fill>
                    <Card width={'medium'}>
                        <CardBody>
                            <List 
                                data={menuItems} 
                                children={(item) => <Button label={item.title[i18n.language]} icon={item.icon} fill onClick={() => navigate(item.link)} justify="start" />} 
                                pad={{ bottom: 'small' }}
                            />
                        </CardBody>
                    </Card>
                    <Box fill>
                        <Outlet />
                    </Box>
                </Box>
            </BoxWindow>
        </Box>
    )
}