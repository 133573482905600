import { Avatar, Box, Button, Card, CardBody, Heading } from "grommet"
import { Money, User } from "grommet-icons"
import { MemesDepositDialog } from "./dialog/MemesDepositDialog"
import { useState } from "react";
import { useGetCurrentUserQuery, useGetUserPointBalanceQuery } from "../../api/userApi";
import { formattedPrice } from "../../utils/priceUtils";
import { authenticationSelector } from "../../slices/authSlice";
import { useSelector } from "react-redux";

export const MemesUserHeader = ({ username, monobankJarUrl }) => {

    const isAuthenticated = useSelector(authenticationSelector);
    const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, {skip: !isAuthenticated});
    const { data: pointBalance, isSuccess: isPointBalanceLoaded } = useGetUserPointBalanceQuery({ username: username }, { skip: !isAuthenticated || isCurrentUserSuccess && currentUser.username === username });
    const [showDepositDialog, setShowDepositDialog] = useState(false);

    return (
        <>
            <Card background="#222222" fill margin={{top: 'medium'}}>
                <CardBody pad="medium" direction="row" justify="between">
                    <Box direction="row" gap="small" align="center">
                        {/* <Avatar size="medium" src="https://static-cdn.jtvnw.net/jtv_user_pictures/adc955ce-4fb5-4255-8943-7d5cce4c9f03-profile_image-300x300.png"/> */}
                        <User size="large"/>
                        <Heading level={2} weight={'bolder'} >{username}</Heading>
                    </Box>
                    <Box direction="row" align="center" gap="small">
                        {/* <TwitchAuth /> */}
                        {isPointBalanceLoaded && <Button label={`${formattedPrice(pointBalance.pointBalance)}`} primary icon={<Money/>} reverse gap="small" onClick={() => setShowDepositDialog(true)}/>}
                    </Box>
                </CardBody>
            </Card>
            <MemesDepositDialog show={showDepositDialog} username={username} monobankJarUrl={monobankJarUrl} onClose={() => setShowDepositDialog(false)} />
        </>
    )
}