import { Box, Grid } from "grommet"
import { createContext, memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import './MemesGrid.css'
import { WebGLCanvas } from "./WebGLCanvas";
import MemesColumn from "./MemesColumn";
import { MemeDialog } from "./dialog/MemeDialog";
import { useDispatch, useSelector } from "react-redux";
import { setMemeModal } from "../../slices/modalWindowsSlice";
import { useOnWindowResize, useOnWindowScroll } from "rooks";
import MemeWithChromaKeyPreview from "./MemeWithChromaKeyPreview";
import MemePreview from "./MemePreview";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";

export const MemeHoverContext = createContext();

export const MemesGrid = ({ memes, priceConfig, isDonatePage = false, hasNext, onLoad }) => {

    const dispatch = useDispatch();

    const { show, meme } = useSelector((state) => state.modalWindows.memeModal);

    const [canvas, setCanvas] = useState();

    const [containerNode, setContainerNode] = useState();
    const [containerOffset, setContainerOffset] = useState({ top: 0, left: 0 });
    const [canvasPosition, setCanvasPosition] = useState({ transform: `translate(${0}px, ${0}px)` });
    const [showCanvas, setShowCanvas] = useState();
    const [video, setVideo] = useState();
    const [currentMeme, setCurrentMeme] = useState();
    const [hoverTimeout, setHoverTimeout] = useState();
    const [mouseEnterData, setMouseEnterData] = useState();

    const canvasRef = useCallback((node) => {
        if (!node || !node.getCanvas) return;

        setCanvas(node);
    }, [])

    const handleHoverEvent = (type, moveTo, size, meme, video) => {
        setCurrentMeme(meme);
        if (type === 'mouseenter') {
            
            setVideo(video);

            setMouseEnterData({ moveTo, size, video });

            return true;
        }
        if (type === 'mouseleave') {
            handleMouseLeave(video);

            return false;
        }
    }

    const handleMouseEnter = (moveTo, size, video) => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }

        setCanvasPosition({ transform: `translate(${moveTo.left - containerOffset.left - window.scrollX}px, ${moveTo.top - containerOffset.top - window.scrollY}px)` });
        
        canvas.getCanvas().setAttribute('height', size.height);
        canvas.getCanvas().setAttribute('width', size.width);
        
        video.currentTime = 0;
        const playPromise = video.play();
        playPromise.then(_ => {
            console.log('play started');
        })
        .catch(error => {
            console.log('ERROR!!!!!!, autoplay prevented ');
        })
        video.loop = true;
        canvas.play();
        setShowCanvas(true);
    }

    const handleMouseLeave = (video) => {
        setShowCanvas(false);
        const timeoutId = setTimeout(() => {
            video.pause();
            video.loop = false;
        }, 250);

        canvas.stop();

        setHoverTimeout(timeoutId);
    }

    const handleHideCanvas = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setShowCanvas(false);
        video.pause();
        video.loop = false;
        canvas.stop();
    }

    useEffect(() => {
        if (video && mouseEnterData) {
            handleMouseEnter(mouseEnterData.moveTo, mouseEnterData.size, mouseEnterData.video);
        }
    }, [video, mouseEnterData])

    useEffect(() => {
        if (show && meme?.chromaKey) {
            handleHideCanvas(meme);
        }
    }, [show, meme])

    const handlePosition = useCallback((memeId, moveTo, size) => {

        if (currentMeme?.id === memeId) {

            setCanvasPosition({ transform: `translate(${moveTo.left - containerOffset.left - window.scrollX}px, ${moveTo.top - containerOffset.top - window.scrollY}px)` });

            if (canvas) {
                canvas.getCanvas().setAttribute('height', size.height);
                canvas.getCanvas().setAttribute('width', size.width);
            }
        }
    }, [currentMeme, canvas, containerOffset])

    const handleModalClose = () => {
        dispatch(setMemeModal({ show: false }));
    }

    const containerRef = useCallback((node) => {
        if (!node) return;

        const rect = node.getBoundingClientRect();
        setContainerOffset({ top: rect.top, left: rect.left });
        setContainerNode(node);
    }, [])

    useLayoutEffect(() => {
        if (containerNode) {
            const rect = containerNode.getBoundingClientRect();
            setContainerOffset({ top: rect.top - window.scrollY, left: rect.left - window.scrollX });
        }
    }, [containerNode])

    useOnWindowResize(() => {
        const rect = containerNode.getBoundingClientRect();
        setContainerOffset({ top: rect.top - window.scrollY, left: rect.left - window.scrollX });
    }, [containerNode], true)

    useOnWindowScroll(() => {
        const rect = containerNode.getBoundingClientRect();
        setContainerOffset({ top: rect.top, left: rect.left });
    }, [containerNode], true)

    return (
        <MemeHoverContext.Provider value={{ handleHoverEvent, handlePosition }}>
            <Box ref={containerRef}>
                <WebGLCanvas 
                    video={video}
                    chromaKey={currentMeme?.chromaKey}
                    similarity={currentMeme?.similarity}
                    smoothness={currentMeme?.smoothness}
                    spill={currentMeme?.spill}
                    style={{ position: 'absolute', ...canvasPosition, zIndex: 1, pointerEvents: 'none', display: showCanvas ? 'block' : 'none' }} 
                    ref={canvasRef}
                    className="video-preview"
                />
                {memes?.length > 0 && <Box fill>
                    <InfiniteScroll
                        dataLength={memes.length}
                        next={onLoad}
                        hasMore={hasNext}
                        loader={<h4>Loading...</h4>}
                    >
                        <Masonry
                            breakpointCols={4}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {memes.map((meme) => meme.chromaKey ? <MemeWithChromaKeyPreview key={meme.id} meme={meme} /> : <MemePreview key={meme.id} meme={meme} />)}
                        </Masonry>
                    </InfiniteScroll>
                </Box>}
                <MemeDialog show={show} meme={meme} priceConfig={priceConfig} onClose={handleModalClose} isDonatePage={isDonatePage}/>
            </Box>
        </MemeHoverContext.Provider>
    )
}

export default memo(MemesGrid);