import { Box, Button, Card, CardBody, CardFooter, CardHeader, Form, FormField, Heading, Layer, Notification, Text, TextInput } from "grommet"
import { Money } from "grommet-icons"
import { useEffect, useState } from "react"
import { useGetDepositPromotionsQuery } from "../../../api/interactionsApi"
import { formattedPrice } from "../../../utils/priceUtils"
import { useCreatePointsDonateRecordMutation } from "../../../api/donateRecordsApi"
import { useNavigate } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"

const DepositOption = ({ value, discountedValue, discount, onClick }) => {
    return (
        <Box direction="column" gap="small" align="center" justify="center" border={{ color: '#ffffff5c', size: '1px' }} round height={'120px'} width={'120px'} hoverIndicator={{ background: { color: 'background-contrast' }, elevation: 'medium' }} onClick={() => onClick(value)}>
            <Box direction="row" gap="xsmall" align="center">
                <Text weight={'bold'}>{formattedPrice(value)}</Text><Money/>
            </Box>
            <Box direction="column" gap="xxsmall" align="center">
                {discount > 0 && <Box direction="row" gap="xxsmall" align="center">
                    <Text weight={'lighter'} size="xsmall" style={{ textDecoration: 'line-through' }}>{value} ₴</Text>
                    <Box pad={'xxsmall'} alignSelf="end" round="xsmall" background={'red'} align="center">
                        <Text weight={'lighter'} size="xsmall">-{discount}%</Text>
                    </Box>
                </Box>}
                <Text>{formattedPrice(discountedValue)} ₴</Text>
            </Box>
        </Box>
    )
}

export const MemesDepositDialog = ({ show, username, monobankJarUrl, onClose }) => {

    const { t } = useTranslation('memes');
    const { data: depositPromotions, isSuccess: isDepositPromotionsLoaded } = useGetDepositPromotionsQuery();
    const [createDonationRecord] = useCreatePointsDonateRecordMutation();
    const [selectedDeposit, setSelectedDeposit] = useState(0);
    const [depositInputValue, setDepositInputValue] = useState(10);
    const [calculatedDeposit, setCalculatedDeposit] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handleDepositSelect = (value) => {
        setSelectedDeposit(value);
        setCalculatedDeposit('');
    }
    const handleDepositInput = (event) => {
        setSelectedDeposit('');
        
        const value = event.target.value;

        if (Number(value) > 10000) {
            return;
        }

        if (Number(value) < 10) {
            setErrorMessage('Min deposit amount is 10');
        } else {
            setErrorMessage('');
        }

        if (value === "" || /^-?\d+$/.test(value)) {
            setDepositInputValue(value);

            const discount = depositPromotions.reduce((acc, depositPromotion) => Number(value) * 100 >= depositPromotion.pointsAmount ? depositPromotion.discount : acc, depositPromotions[0].discount);
        
            setCalculatedDeposit(Number(value) * 100 - (Number(value) * discount));
        }
    }

    const handleDeposit = () => {
        const body = {
            pointsAmount: selectedDeposit || Number(depositInputValue) * 100,
            receiver: username
        }

        createDonationRecord(body).then(response => {
            const data = response.data;

            const donateId = data.id;
            const amount = data.donateAmount;

            window.open(`${monobankJarUrl}?a=${amount / 100}&t=${donateId}`, '_blank').focus();
        })
        
    }

    useEffect(() => {
        if (isDepositPromotionsLoaded) {
            setCalculatedDeposit(depositInputValue * 100 - (depositInputValue * depositPromotions[0].discount))
        }
    }, [isDepositPromotionsLoaded])

    if (show) {
        return (
            <Layer onClickOutside={() => onClose()}>
                <Card background="#222222">
                    <CardHeader pad={{ left: 'medium', right: 'medium', top: 'medium', bottom: 'none' }}>
                        <Heading level={2} weight={'bold'} margin={'none'}>{t('depositFundsTitle')}</Heading>
                    </CardHeader>
                    <CardBody pad={{ left: 'medium', right: 'medium', top: 'medium', bottom: 'none' }}>
                        <Box direction="column" gap="small">
                            <Box direction="row-response" gap="small" justify="between">
                                {isDepositPromotionsLoaded && depositPromotions.filter((depositPromotion, index) => index < 3).map(depositPromotion => 
                                    <DepositOption key={depositPromotion.id} value={depositPromotion.pointsAmount} discountedValue={depositPromotion.currencyAmount} discount={depositPromotion.discount} onClick={handleDepositSelect} />
                                )}
                            </Box>
                            <Box direction="row-response" gap="small" justify="between">
                                {isDepositPromotionsLoaded && depositPromotions.filter((depositPromotion, index) => index >= 3).map(depositPromotion => 
                                    <DepositOption key={depositPromotion.id} value={depositPromotion.pointsAmount} discountedValue={depositPromotion.currencyAmount} discount={depositPromotion.discount} onClick={handleDepositSelect} />
                                )}
                            </Box>
                            <Box direction="row" align="center" gap="small" fill>
                                <Box fill basis="medium">
                                    <Form>
                                        <FormField name="deposit-amount" htmlFor="deposit-amount" label={t('depositAmountTitle')} error={errorMessage}>
                                            <TextInput name="deposit-amount" id="deposit-amount" type="text" placeholder={t('depositAmountPlaceholder')} value={depositInputValue} onFocus={handleDepositInput} onChange={handleDepositInput} />
                                        </FormField>
                                    </Form>
                                </Box>
                                <Box margin={{ top: 'medium' }} basis="small">
                                    <Box>
                                        <Text truncate="tip">= {formattedPrice(calculatedDeposit)} ₴</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardBody>
                    <CardFooter pad="medium" direction="column">
                        {!monobankJarUrl && <Notification status="warning" title={t('depositFundsWarningMessage')} />}
                        {(selectedDeposit === 0 || selectedDeposit === '') && (calculatedDeposit === 0 || calculatedDeposit === '') && (<Button fill disabled label={t('selectDepositButton')} />)}
                        {(selectedDeposit > 0) && (<Button fill label={<Trans i18nKey="memes:depositButton" values={{ value: formattedPrice(selectedDeposit) }} />} icon={<Money/>} reverse primary onClick={handleDeposit} disabled={!monobankJarUrl} />)}
                        {(calculatedDeposit > 0) && (<Button fill label={<Trans i18nKey="memes:depositButton" values={{ value: depositInputValue }} />} icon={<Money/>} reverse primary onClick={handleDeposit} disabled={!monobankJarUrl} />)}
                    </CardFooter>
                </Card>
            </Layer>
        )
    }
}