import { Box, Button, Card, CardBody, FormField, Heading, Image, Notification, RadioButtonGroup, Spinner, Text, TextInput } from "grommet"
import { Bundle, Clear, Connect, Detach, Download, LinkNext, Share } from "grommet-icons"
import { useLazyGetMonobankJarsQuery, useSetupMonobankWebhookMutation } from "../../api/monobankApi"
import { useEffect, useState } from "react";
import { formattedPrice } from "../../utils/priceUtils";
import { useConnectMonobankJarMutation, useDisconnectMonobankJarMutation, useGetMonobankJarQuery } from "../../api/userApi";
import { Trans, useTranslation } from "react-i18next";

export const MonobankSettings = () => {

    const { t, ready } = useTranslation('monobank-settings', {
		useSuspense: false,
	});
    const { data: currentMonobankJar, isSuccess: isCurrentMonobankJarSuccess, isError: isCurrentMonobankJarError, refetch: refetchCurrentMonobankJar } = useGetMonobankJarQuery();
    const [getMonobankJars, { isSuccess: isJarsReceived }] = useLazyGetMonobankJarsQuery();
    const [connectMonobankJar] = useConnectMonobankJarMutation();
    const [setupMonobankWebhook] = useSetupMonobankWebhookMutation();
    const [disconnectMonobankJar] = useDisconnectMonobankJarMutation();
    const [monoToken, setMonoToken] = useState();
    const [monobankJars, setMonobankJars] = useState([]);
    const [jarOptions, setJarOptions] = useState([]);
    const [selectedJar, setSelectedJar] = useState();

    const handleReceiveMonobankJars = () => {
        if (!monoToken) return;
        getMonobankJars({ token: monoToken })
            .then(response => {
                const info = response.data;

                const jars = info?.jars;

                console.log(jars);
                
                if (jars) {
                    setMonobankJars(jars);
                }

            });
    }

    useEffect(() => {
        if (monobankJars?.length > 0) {
            const jarOptions = monobankJars.map(jar => ({
                disabled: false,
                id: jar.id,
                label:  <MonobankaPreview title={jar.title} balance={jar.balance} goal={jar.goal} />,
                value: jar.id
            }));

            setJarOptions(jarOptions);
        }
    }, [monobankJars])

    const handleJarSelect = (event) => {
        const jarId = event.value;
        const jar = monobankJars.filter(jar => jar.id === jarId)?.pop();
        console.log(jar);
        setSelectedJar(jar);

    }

    const handleConnectJar = () => {
        connectMonobankJar({
            id: selectedJar.id,
            sendId: selectedJar.sendId,
            title: selectedJar.title,
            currencyCode: selectedJar.currencyCode,
            balance: selectedJar.balance,
            goal: selectedJar.goal
        }).then(response => {
            setMonobankJars([]);
            setJarOptions([]);
            const webhookToken = response.data.webHookToken;
            setupMonobankWebhook({ token: monoToken, body: { webHookUrl: `${process.env.REACT_APP_API_BASE_URL}/monobank/personal/webhook/${webhookToken}` } })
                .then(() => refetchCurrentMonobankJar());
        });
    }

    const handleCancelJar = () => {
        setMonobankJars([]);
        setJarOptions([]);
    }

    const handleDisconnectJar = () => {
        disconnectMonobankJar({ id: currentMonobankJar.id }).then(() => {
            console.log('refetch');
            refetchCurrentMonobankJar()
            
        })
    }

    useEffect(() => {
        console.log(isCurrentMonobankJarError);
        
    }, [isCurrentMonobankJarError])

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Box direction="row" margin={{ top: 'medium' }}>
                <Card background="#222222" flex>
                    <CardBody pad="medium" direction="column">
                        <Box direction="row" gap="small" align="center" margin={{ bottom: 'small' }}>
                            <Image height={'30px'} width={'30px'} src={`${process.env.PUBLIC_URL}/images/monobank-logo.png`} /><Heading margin={{ top: 'none', bottom: 'none' }} level={2} size="medium" weight={'bold'}>{t('title')}</Heading>
                        </Box>
                        <Box direction="row" border={{ color: 'background-contrast', side: 'top', size: 'small' }}>
                            <Box direction="column" margin={{ top: 'medium' }} width={'large'}>
                                {(!currentMonobankJar || isCurrentMonobankJarError) && <Box direction="column" gap="small" margin={{ bottom: 'small' }}>
                                    <FormField label={t('monobankApiTokenTitle')}>
                                        <TextInput type="password" value={monoToken} onChange={(event) => setMonoToken(event.target.value)} />
                                    </FormField>
                                    <Button icon={<Download/>} label={t('receiveMonoJarsButton')} secondary onClick={handleReceiveMonobankJars} />
                                </Box>}
                                {(!currentMonobankJar || isCurrentMonobankJarError) && <Notification message={t('monoApiDisclaimer')} actions={[{ label: t('monoApiDisclaimerLinkTitle'), href: 'https://api.monobank.ua', target: '_blank' }]} status="info" />}
                                {(!currentMonobankJar || isCurrentMonobankJarError) && jarOptions?.length > 0 && <Box direction="column" gap="small" margin={{ bottom: 'medium' }}>
                                    <RadioButtonGroup options={jarOptions} onChange={handleJarSelect} />
                                    <Box direction="row" gap="small">
                                        <Button icon={<Clear/>} label={t('cancelButton')} justify="center" onClick={handleCancelJar} />
                                        <Button icon={<Connect/>} label={t('connectButton')} secondary justify="center" onClick={handleConnectJar} />
                                    </Box>
                                </Box>}
                                {(!currentMonobankJar || isCurrentMonobankJarError) && isJarsReceived && jarOptions?.length === 0 && <Box margin={{ top: 'small' }}>
                                    <Notification title="No jars found" status="warning" />
                                </Box>}
                                {/* <Notification message="Unable to find any jars. Create a jar in Monobank app and try again." status="warning" /> */}
                                {currentMonobankJar && !isCurrentMonobankJarError && <Box direction="row" justify="between" round="xsmall" border={{ color: '#ffffff5c', style: 'solid', size: '1px' }} pad={'small'} >
                                    <MonobankaPreview title={currentMonobankJar.title} balance={currentMonobankJar.balance} goal={currentMonobankJar.goal} />
                                    <Box direction="column" gap="small">
                                        <Button icon={<Share/>} label={t('openJarButton')} justify="center" href={currentMonobankJar.monobankJarUrl} target="_blank" />
                                        <Button icon={<Detach/>} label={t('disconnectJarButton')} secondary justify="center" onClick={handleDisconnectJar} />
                                    </Box>
                                </Box>}
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </Box>
        )
    }
}

const MonobankaPreview = ({ title, balance, goal }) => {

    return (
        <Box direction="column" gap="small" margin={{ top: 'xsmall', bottom: 'xsmall' }}>
            <Text size="small" weight={'bold'}>{title}</Text>
            <Box direction="column" gap="xsmall">
                <Text size="xsmall" weight={400}><Trans i18nKey="monobank-settings:balance" values={{ balance: formattedPrice(balance) }}/></Text>
                {goal && <Text size="xsmall" weight={400}><Trans i18nKey="monobank-settings:goal" values={{ goal: formattedPrice(goal) }}/></Text>}
            </Box>
        </Box>
    )
}