import { Card, CardBody } from "grommet"

export const PaymentsHistoryPage = () => {

    return (
        <Card fill>
            <CardBody>
                Payments History
            </CardBody>
        </Card>
    )
}