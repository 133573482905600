import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const donateRecordsApi = createApi({
    reducerPath: 'donateRecordsApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/donaterecords`, credentials: 'include' }),
    endpoints: (builder) => ({
        createPointsDonateRecord: builder.mutation({
            query: (body) => {
                return {
                    url: `/points`,
                    method: 'POST',
                    body: body
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response
        })
    }),
})

export const { useCreatePointsDonateRecordMutation } = donateRecordsApi