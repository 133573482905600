import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        data: [],
        current: undefined
    },
    reducers: {
        addNotification: (state, action) => {
            if (state.data.length === 0) {
                state.current = action.payload;
                state.data.push(action.payload);
            } else {
                state.data.push(action.payload);
            }
        },
        hideNotification(state) {
            state.data.splice(0, 1);
            if (state.data.length > 0) {
                state.current = state.data[0];
            } else {
                state.current = undefined;
            }
        }
    }
})

export const { addNotification, hideNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;