import { Box, Button, Data, DataFilters, DataTable, Image, Text, Toolbar } from "grommet"
import { Money, Refresh } from "grommet-icons"
import { formattedPrice } from "../../utils/priceUtils"
import { useReplayInteractionMutation } from "../../api/eventRecordsApi"
import { format } from "date-fns"
import { uk } from 'date-fns/locale/uk'
import { useTranslation } from "react-i18next"

const properties = {
    'data.type': {
        label: 'Type',
        options: [
            { label: 'Interaction', value: 'INTERACTION' },
            { label: 'Points Buy', value: 'INTERACTIONS_POINTS_BUY' },
            { label: 'Auction Donate', value: 'AUCTION_DONATE' },
            { label: 'Auction Twitch Points', value: 'AUCTION_TWITCH_POINTS' },
            { label: 'Roulette Donate', value: 'ROULETTE_DONATE' }
        ]
    }
}

const eventTypes = {
    INTERACTION: {
        title: 'Interaction',
        color: '#c94700'
    },
    INTERACTIONS_POINTS_BUY: {
        title: 'Points Buy',
        color: '#0094b5'
    },
    AUCTION_DONATE: {
        title: 'Auction Donate',
        color: '#007d2e'
    },
    AUCTION_TWITCH_POINTS: {
        title: 'Auction Twitch Points',
        color: '#5400ba'
    },
    ROULETTE_DONATE: {
        title: 'Roulette Donate',
        color: '#005ac2'
    },
}

export const EventsTable = ({ data, total, step, onMore }) => {

    const { t } = useTranslation('dashboard');

    return (
        <Box overflow={'scroll'}>
            <Data properties={properties} data={data} total={total}>
                <Toolbar align="center" justify="end">
                    <Box direction="row" gap="small">
                        <DataFilters drop />
                        {/* <Select options={['Most valued', 'Newest']} /> */}
                    </Box>
                </Toolbar>
                <DataTable
                    columns={[
                        {
                            property: 'data',
                            header: <Text weight={'bold'}>{t('infoTitle')}</Text>,
                            render: datum => (<EventRecordInfo datum={datum} />),
                            size: 'medium'
                        },
                        {
                            property: 'currencyAmount',
                            header: <Text weight={'bold'}>{t('amountTitle')}</Text>,
                            render: datum => (<EventRecordAmount datum={datum} />),
                            align: 'center',
                            size: 'xsmall'
                        },
                        {
                            property: 'id',
                            header: <Text weight={'bold'}>{t('actionsTitle')}</Text>,
                            render: datum => (<EventRecordActions datum={datum} />),
                            size: 'xsmall'
                        }
                    ]}
                    primaryKey="id"
                    fill
                    border={{ 
                        header: 'bottom',
                        body: {
                            side: 'bottom',
                            style: 'solid',
                            size: '0.5px'
                        }
                    }}
                    pad={{ left: 'xsmall', top: 'small', bottom: 'small', right: 'xxsmall' }}
                    pin
                    step={step}
                    onMore={onMore}
                />
            </Data>
        </Box>
    )
}

const EventRecordInfo = ({ datum }) => {

    return (
        <Box direction="column" gap="xsmall" >
            <Box direction="row" justify="between" align="center">
                <Box direction="row" gap="small" align="center">
                    <Box pad={'xxsmall'} round="xsmall" background={eventTypes[datum.data.type].color} align="center"><Text size="xsmall">{eventTypes[datum.data.type].title}</Text></Box>
                    <Text weight={'bold'}>{datum.data.sender}</Text>
                </Box>
                <Text size="xsmall" weight={'lighter'} color="#949494">{format(datum.data.creationTime, 'PPP, HH:mm', { locale: uk })}</Text>
            </Box>
            {datum.data.interactionPreview && <Box direction="column" gap="small">
                <Text size="small">{datum.data.interactionTitle}</Text>
                <Image src={datum.data.interactionPreview} style={{ maxWidth: '150px' }} />
            </Box>}
            {datum.data.message && datum.data.type !== 'INTERACTION' && <Text size="xsmall">{datum.data.message}</Text>}
        </Box>
    )
}

const EventRecordAmount = ({ datum }) => {

    return (
        <Box direction="column" gap="xsmall" align="center">
            {datum.currencyAmount && <Text>+{formattedPrice(datum.currencyAmount)}₴</Text>}
            {datum.pointsAmount && <Box direction="row" gap="xsmall" align="center">
                <Text size="xsmall" weight={'lighter'} color="#949494">({formattedPrice(datum.pointsAmount)}</Text><Money size="14px" color="#949494"/><Text size="xsmall" weight={'lighter'} color="#949494">)</Text>
            </Box>}
        </Box>
    )
}

const EventRecordActions = ({ datum }) => {

    const { t } = useTranslation('dashboard');

    const [replayInteraction] = useReplayInteractionMutation();

    const handleReplay = () => {
        replayInteraction({ eventId: datum.id })
    }

    return (
        <Box>{['INTERACTION', 'ROULETTE_DONATE'].includes(datum.data.type) && <Button label={t('replayButton')} icon={<Refresh />} size="small" onClick={handleReplay} />}</Box>
    )
}