import { Box, Button, RangeInput } from "grommet"
import { WebGLCanvas } from "./WebGLCanvas"
import { forwardRef, useCallback, useEffect, useLayoutEffect, useState } from "react"
import { Pause, Play } from "grommet-icons";

export const VideoTransparentPreview = ({ src, chromaKeyConfig, hidePlayer = false, roundCorners = false, onEnded, ...props }) => {

    const [removeBackground, setRemoveBackground] = useState(false);
    const [canvasNode, setCanvasNode] = useState();
    const [canvasApi, setCanvasApi] = useState();
    const [videoNode, setVideoNode] = useState();
    const [videoSize, setVideoSize] = useState({ height: 0, width: 0 });
    const [isPlaying, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const canvasRef = useCallback((node) => {
        if (!node || !node.getCanvas) return;

        setCanvasNode(node.getCanvas());
        setCanvasApi(node);
    }, [])

    const videoRef = useCallback((node) => {
        if (!node) return;

        setVideoNode(node);
    }, [])
    
    useLayoutEffect(() => {
        if (videoNode) {
            const resizeObserver = new ResizeObserver(() => {
                const rect = videoNode.getBoundingClientRect();
                // console.log(rect);
                            
                setVideoSize({ height: rect.height, width: rect.width });
            });
            resizeObserver.observe(videoNode);
    
            return () => resizeObserver.disconnect();
        }
    }, [videoNode])

    useLayoutEffect(() => {
        if (canvasNode) {
            canvasNode.height = videoSize.height;
            canvasNode.width = videoSize.width;
            canvasNode.style.height = `${videoSize.height}px`;
            canvasNode.style.width = `${videoSize.width}px`;
        }
    }, [canvasNode, videoSize])

    useLayoutEffect(() => {
        return () => {
            if (isPlaying) {
                videoNode?.pause();
                setPlaying(false);
            }
        }
    }, [videoNode, isPlaying])

    useEffect(() => {
        if (chromaKeyConfig) {
            console.log('REMOVING BACKGROUND...');
            
            setRemoveBackground(true);
        } else {
            setRemoveBackground(false);
        }
    }, [chromaKeyConfig])

    const onVideoPlay = () => {
        console.log('play');
        setPlaying(true);
        
        canvasApi?.play();
    }

    const onVideoReady = (event) => {
        console.log('ready');

        const video = event.target;

        setDuration(video.duration);
        
        const { height, width } = videoNode.getBoundingClientRect();
        
        setVideoSize({ height: height, width: width });

        if (!isPlaying) {
            console.log('play');
            
            videoNode.play().then(_=> setPlaying(true));
        }
    }

    const handleMoveTimeline = (event) => {
        videoNode.currentTime = event.target.value;
    }

    const handleTimelinePause = () => {
        videoNode.pause();
    }

    const handleTimelinePlay = () => {
        videoNode.play();
    }

    const onVideoTimeChange = () => {
        setCurrentTime(videoNode.currentTime);
    }

    const handleVideoPlay = () => {
        if (!isPlaying) {
            videoNode.play();
        }
        if (isPlaying) {
            videoNode.pause();
        }
    }

    const onVideoPause = () => {
        setPlaying(false);
    }

    const onVideoEnded = () => {
        if (onEnded) {
            onEnded();
        }
    }

    return (
        <Box style={{ display: src ? 'flex' : 'none' }}>
            <Box direction="column" className={roundCorners ? 'video-preview' : ''} style={{ position: 'relative' }}>
                <Box /*className="react-player"*/ style={{ opacity: removeBackground ? 0 : 1 }}>
                    <VideoPlayer 
                        src={src} 
                        onPlay={onVideoPlay} 
                        onLoadedData={onVideoReady}
                        onPause={onVideoPause}
                        onEnded={onVideoEnded}
                        onTimeUpdate={onVideoTimeChange} 
                        ref={videoRef}
                        {...props}
                        volume={0.2}
                        // muted
                    />
                </Box>
                <WebGLCanvas
                    video={videoNode}
                    chromaKey={chromaKeyConfig?.chromaKey}
                    similarity={chromaKeyConfig?.similarity}
                    smoothness={chromaKeyConfig?.smoothness}
                    spill={chromaKeyConfig?.spill}
                    ref={canvasRef}
                    style={{ position: 'absolute', opacity: removeBackground ? 1 : 0, width: '100%', height: '100%' }}
                    //className="canvas-preview"
                />
            </Box>
            {!hidePlayer && <Box direction="row" gap="xsmall" align="center">
                <Button icon={isPlaying ? <Pause/> : <Play/>} onClick={handleVideoPlay} />
                <RangeInput 
                    value={currentTime} 
                    min={0} 
                    max={duration} 
                    step={0.001} 
                    onMouseDown={handleTimelinePause} 
                    onMouseUp={handleTimelinePlay} 
                    onChange={handleMoveTimeline} 
                />
            </Box>}
        </Box>
    )
}

const VideoPlayer = forwardRef(({ src, ...props }, ref) => {

    return (
        <video 
            ref={ref}
            playsInline
            height={'100%'}
            width={'100%'}
            src={src}
            crossOrigin="anonymous"
            {...props}
        >
        </video>
    )
})

