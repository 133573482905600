import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { interactionsApi } from './interactionsApi'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/users`, credentials: 'include' }),
    tagTypes: ['User', 'Reward', 'Interactions', 'FavoriteInteractions', 'InteractionSettings', 'MonobankJar', 'UserPointBalance'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => `/current`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['User'],
        }),
        partialUserUpdate: builder.mutation({
            query: (body) => ({url: `/current`, method: 'PATCH', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: ['User'],
        }),
        getUserDonates: builder.query({
            query: () => ({url: `/current/donates`}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        }),
        getUserRewards: builder.query({
            query: () => ({url: `/current/rewards`}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['Reward']
        }),
        createUserReward: builder.mutation({
            query: (body) => ({url: `/current/rewards`, method: 'POST', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Reward']
        }),
        deleteUserReward: builder.mutation({
            query: (id) => {
                return {url: `/current/rewards/${id}`, method: 'DELETE'}
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Reward']
        }),
        logoutUser: builder.mutation({
            query: () => {
                return {url: `/current/logout`, method: 'POST'}
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['User']
        }),
        getFavoriteInteractions: builder.query({
            query: (args) => {
                if (args.term) {
                    return {
                        url: `/current/favorites/interactions`,
                        method: 'GET',
                        params: { term: args.term }
                    }
                }
                return {
                    url: `/current/favorites/interactions`,
                    method: 'GET',
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['FavoriteInteractions']
        }),
        addToFavorites: builder.mutation({
            query: (args) => {
                return {
                    url: `/current/favorites/interactions/${args.interactionId}`,
                    method: 'POST',
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Interactions', 'FavoriteInteractions']
        }),
        removeFromFavorites: builder.mutation({
            query: (args) => {
                return {
                    url: `/current/favorites/interactions/${args.interactionId}`,
                    method: 'DELETE',
                }
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
                try {
                    await cacheDataLoaded;
                    dispatch(interactionsApi.util.invalidateTags(['Interactions']));
                } catch {
                    console.log('error');
                }
                await cacheEntryRemoved;
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Interactions', 'FavoriteInteractions']
        }),
        getInteractionSettings: builder.query({
            query: () => ({url: `/current/interaction-settings`}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['InteractionSettings']
        }),
        updateInteractionSettings: builder.mutation({
            query: (body) => {
                return {
                    url: `/current/interaction-settings`,
                    method: 'PATCH',
                    body: body
                }
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
                try {
                    await cacheDataLoaded;
                    dispatch(interactionsApi.util.invalidateTags(['UserInteractionSettings']));
                } catch {
                    console.log('error');
                }
                await cacheEntryRemoved;
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['InteractionSettings']
        }),
        generateNewOverlayToken: builder.mutation({
            query: () => {
                return {
                    url: `/current/interaction-settings/token`,
                    method: 'POST',
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['InteractionSettings']
        }),
        connectMonobankJar: builder.mutation({
            query: (body) => {
                return {
                    url: `/current/monobank/jar`,
                    method: 'POST',
                    body: body
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            // invalidatesTags: ['MonobankJar']
        }),
        getMonobankJar: builder.query({
            query: () => ({ url: `/current/monobank/jar/current` }),
            transformResponse: (response) => response,
            transformErrorResponse: (error) => {
                if (error.status === 404) {
                    // Clear cache by returning null or a default value
                    return null; 
                }
                return error;
            },
            providesTags: ['MonobankJar'],
        }),
        disconnectMonobankJar: builder.mutation({
            query: (args) => {
                return {
                    url: `/current/monobank/jar/${args.id}`,
                    method: 'DELETE'
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['InteractionSettings', 'MonobankJar']
        }),
        getUserPointBalance: builder.query({
            query: (args) => ({ url: `/current/balances/${args.username}` }),
            transformResponse: (response) => response,
            transformErrorResponse: (error) => error,
            providesTags: ['UserPointBalance'],
        }),
    }),
})

export const { useGetCurrentUserQuery, 
    useLazyGetUserDonatesQuery, 
    useGetUserRewardsQuery, 
    usePartialUserUpdateMutation, 
    useCreateUserRewardMutation, 
    useDeleteUserRewardMutation, 
    useLogoutUserMutation, 
    useGetFavoriteInteractionsQuery, 
    useAddToFavoritesMutation, 
    useRemoveFromFavoritesMutation,
    useGetInteractionSettingsQuery,
    useUpdateInteractionSettingsMutation,
    useGenerateNewOverlayTokenMutation, 
    useConnectMonobankJarMutation,
    useGetMonobankJarQuery,
    useDisconnectMonobankJarMutation,
    useGetUserPointBalanceQuery } = userApi