import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { Provider } from 'react-redux';
import './i18n'
import { addLots } from './slices/auctionDataSlice';

// temporary migration script to move old data from local storage to database
const migrateOldData = () => {
	if (localStorage.getItem('data') != undefined) {
		const data = JSON.parse(localStorage.getItem('data'));
    store.dispatch(addLots(data.map(row => ({ lotName: row.lotName, cost: row.cost }))));
    localStorage.removeItem('data');
	}
}

migrateOldData();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <App />
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>
  // <Provider store={store}>
  //   <App/>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
