import { BoxWindow } from "../components/BoxWindow"
import { DonatelloSettings } from "../components/auc-settings/DonatelloSettings"
import { DonatikSettings } from "../components/auc-settings/DonatikSettings"
import { MemesSettings } from "../components/auc-settings/MemesSettings"
import { MonobankSettings } from "../components/auc-settings/MonobankSettings"

export const SettingsPage = () => {

    return (
        <BoxWindow topPadding>
            <DonatelloSettings/>
            <DonatikSettings/>
            <MonobankSettings/>
            <MemesSettings />
        </BoxWindow>
    )
}