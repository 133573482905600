import { Box, Main, Notification } from "grommet"
import { AppHeader } from "../components/layout/AppHeader"
import { Outlet } from "react-router-dom"
import { AppFooter } from "../components/layout/AppFooter"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { hideNotification } from "../slices/notificationsSlice"

export const HomePage = () => {

    const notification = useSelector((state) => state.notifications.current)
    const dispatch = useDispatch();
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
      if (notification !== undefined) {

        setShowToast(true);

        const timeoutId = setTimeout(() => setShowToast(false), 8000);
  
        return () => clearTimeout(timeoutId);
      }
    }, [notification])

    useEffect(() => {
      if (!showToast) {
        dispatch(hideNotification());
      }
    }, [showToast])

    return (
        <Box background={{ color: '#1c1c1c' }} height={{ min: '100vh' }}>
          <AppHeader></AppHeader>
          <Main>
            {showToast && <Notification
                toast={{ position: 'top-right' }}
                status={notification.status}
                message={notification.message}
                onClose={() => setShowToast(false)}
            />}
            <Outlet/>
          </Main>
          <AppFooter></AppFooter>
        </Box>
    )
}