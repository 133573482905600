import { divide } from "mathjs";

export const formattedPrice = (price) => {

    const dividedPrice = divide(price, 100);

    if (Number.isInteger(dividedPrice)) {
        return dividedPrice.toString();
    }
    
    return dividedPrice.toFixed(2);
}