import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const monobankApi = createApi({
    reducerPath: 'monobankApi',
    baseQuery: fetchBaseQuery({ baseUrl: `https://api.monobank.ua` }),
    endpoints: (builder) => ({
        getMonobankJars: builder.query({
            query: (args) => ({
                url: `/personal/client-info`,
                headers: {
                    "X-Token": args.token
                }
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
        }),
        setupMonobankWebhook: builder.mutation({
            query: (args) => {
                return {
                    url: `/personal/webhook`,
                    method: 'POST',
                    body: args.body,
                    headers: {
                        "X-Token": args.token
                    }
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response
        }),
        sendToMonobankWebhook: builder.mutation({
            query: (args) => {
                return {
                    url: `/personal/webhook`,
                    method: 'POST',
                    body: args.body
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response
        }),
    }),
})

export const { useLazyGetMonobankJarsQuery, useSetupMonobankWebhookMutation, useSendToMonobankWebhookMutation } = monobankApi