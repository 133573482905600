import { createSlice } from "@reduxjs/toolkit";

export const webGlContextSlice = createSlice({
    name: 'webGlContext',
    initialState: {
        canvas: null,
        context: null,
        coordinates: null
    },
    reducers: {
        setCanvas: (state, action) => {
            state.canvas = action.payload;
        },
        setContext: (state, action) => {
            state.context = action.payload;
        },
        moveCanvas: (state, action) => {
            const moveTo = action.payload;
            // state.canvas.parentElement.parentElement.style.top = `${moveTo.top}px`;
            // state.canvas.parentElement.parentElement.style.left = `${moveTo.left}px`;
            // state.canvas.parentElement.parentElement.style.display = 'block';
        },
        hideCanvas: (state, action) => {
            // state.canvas.parentElement.parentElement.style.display = 'none';
        }
    }
})

export const { setCanvas, setContext, moveCanvas, hideCanvas } = webGlContextSlice.actions;

export default webGlContextSlice.reducer;