import { Box } from "grommet";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";

const vertexShaderSource = `
    attribute vec2 c;
    
    void main(void) {
        gl_Position=vec4(c, 0.0, 1.0);
    }
`

const fragmentShaderSource = `
    precision highp float;

    uniform sampler2D tex;
    uniform float texWidth;
    uniform float texHeight;

    uniform vec3 keyColor;
    uniform float similarity;
    uniform float smoothness;
    uniform float spill;

    // From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
    vec2 RGBtoUV(vec3 rgb) {
        return vec2(
            rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
            rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
        );
    }

    vec4 ProcessChromaKey(vec2 texCoord) {
        vec4 rgba = texture2D(tex, texCoord);

        float chromaDist = distance(RGBtoUV(texture2D(tex, texCoord).rgb), RGBtoUV(keyColor));

        float baseMask = chromaDist - similarity;
        float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
        rgba.a = fullMask;

        float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);
        float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
        rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

        return rgba;
    }

    void main(void) {
        vec2 texCoord = vec2(gl_FragCoord.x / texWidth, 1.0 - (gl_FragCoord.y / texHeight));
        gl_FragColor = ProcessChromaKey(texCoord);
    }
`

export const WebGLCanvas = forwardRef(({ video, chromaKey, similarity, smoothness, spill, ...props }, ref) => {

    const [canvasNode, setCanvasNode] = useState();

    const requestId = useRef();

    const canvasContextRef = useRef();

    const videoRef = useRef();

    const playing = useRef();

    const texLocRef = useRef();
    const texWidthLocRef = useRef();
    const texHeightLocRef = useRef();

    const keyColorLocRef = useRef();
    const similarityLocRef = useRef();
    const smoothnessLocRef = useRef();
    const spillLocRef = useRef();

    const colorKeyRef = useRef();
    const similarityRef = useRef();
    const smoothnessRef = useRef();
    const spillRef = useRef();

    const canvasRef = useCallback(node => {
        if (!node) return;

        setCanvasNode(node);
    }, [])

    useLayoutEffect(() => {
        if (canvasNode) {

            const context = canvasNode.getContext('webgl', {
                premultipliedAlpha: false,
                // preserveDrawingBuffer: true
            });

            canvasContextRef.current = context;
            
            if (context) {
                const vs = context.createShader(context.VERTEX_SHADER);
                
                context.shaderSource(vs, vertexShaderSource);
                context.compileShader(vs);

                const fs = context.createShader(context.FRAGMENT_SHADER);
                context.shaderSource(fs, fragmentShaderSource);
                context.compileShader(fs);
                if (!context.getShaderParameter(fs, context.COMPILE_STATUS)) {
                    console.error(context.getShaderInfoLog(fs));
                }

                const prog = context.createProgram();
                context.attachShader(prog, vs);
                context.attachShader(prog, fs);
                context.linkProgram(prog);
                context.useProgram(prog);

                // context.enable(context.BLEND);
                // context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA);

                const vb = context.createBuffer();
                context.bindBuffer(context.ARRAY_BUFFER, vb);
                context.bufferData(context.ARRAY_BUFFER, new Float32Array([ -1, 1, -1, -1, 1, -1, 1, 1 ]), context.STATIC_DRAW);

                const coordLoc = context.getAttribLocation(prog, 'c');
                context.vertexAttribPointer(coordLoc, 2, context.FLOAT, false, 0, 0);
                context.enableVertexAttribArray(coordLoc);

                context.activeTexture(context.TEXTURE0);
                const tex = context.createTexture();
                context.bindTexture(context.TEXTURE_2D, tex);

                context.texParameteri(context.TEXTURE_2D, context.TEXTURE_WRAP_S, context.CLAMP_TO_EDGE);
                context.texParameteri(context.TEXTURE_2D, context.TEXTURE_WRAP_T, context.CLAMP_TO_EDGE);

                // context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MIN_FILTER, context.NEAREST);
                // context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MAG_FILTER, context.NEAREST);

                context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MIN_FILTER, context.LINEAR);
                context.texParameteri(context.TEXTURE_2D, context.TEXTURE_MAG_FILTER, context.LINEAR);

                texLocRef.current = context.getUniformLocation(prog, "tex");
                texWidthLocRef.current = context.getUniformLocation(prog, "texWidth");
                texHeightLocRef.current =  context.getUniformLocation(prog, "texHeight");

                keyColorLocRef.current = context.getUniformLocation(prog, "keyColor");
                similarityLocRef.current = context.getUniformLocation(prog, "similarity");
                smoothnessLocRef.current = context.getUniformLocation(prog, "smoothness");
                spillLocRef.current = context.getUniformLocation(prog, "spill");
            }

            return () => {
                if (context) {
                    console.log('CLEAR CONTEXT');
                    const extension = context.getExtension('WEBGL_lose_context');
                    if (extension) {
                        extension.loseContext();
                    }
                }
            }
        }
    }, [canvasNode])

    useEffect(() => {
        colorKeyRef.current = chromaKey;
        similarityRef.current = similarity;
        smoothnessRef.current = smoothness;
        spillRef.current = spill;
        videoRef.current = video;
    }, [chromaKey, similarity, smoothness, spill, video])

    const drawFrame = useCallback(() => {
        const videoRect = videoRef.current?.getBoundingClientRect();
        
        if (videoRef.current && colorKeyRef.current && canvasContextRef.current && videoRect.width > 0 && videoRect.height > 0) {
            const canvasContext = canvasContextRef.current;
            // console.log(`draw ${videoRef.current.getAttribute('myId')}`);
            
            canvasContext.viewport(0, 0, videoRect.width, videoRect.height);

            canvasContext.clearColor(0, 0, 0, 0);
            canvasContext.clear(canvasContext.COLOR_BUFFER_BIT);

            canvasContext.texImage2D(canvasContext.TEXTURE_2D, 0, canvasContext.RGB, canvasContext.RGB, canvasContext.UNSIGNED_BYTE, videoRef.current);
            canvasContext.uniform1i(texLocRef.current, 0);
            canvasContext.uniform1f(texWidthLocRef.current, videoRect.width);
            canvasContext.uniform1f(texHeightLocRef.current, videoRect.height);
            
            const m = colorKeyRef.current.match(/^#([0-9a-f]{6})$/i)[1];
            canvasContext.uniform3f(keyColorLocRef.current, parseInt(m.substr(0, 2), 16) / 255, parseInt(m.substr(2, 2), 16) / 255, parseInt(m.substr(4, 2), 16) / 255);
            canvasContext.uniform1f(similarityLocRef.current, similarityRef.current);
            canvasContext.uniform1f(smoothnessLocRef.current, smoothnessRef.current);
            canvasContext.uniform1f(spillLocRef.current, spillRef.current);

            canvasContext.drawArrays(canvasContext.TRIANGLE_FAN, 0, 4);
        }
    }, [])

    const animationLoop = useCallback(() => {
        if (!playing.current) return;

        drawFrame();

        requestId.current = requestAnimationFrame(animationLoop);
    }, [drawFrame]);

    const play = useCallback(() => {
        playing.current = true;

        animationLoop();
    }, [animationLoop]);

    const stop = useCallback(() => {
        playing.current = false;

        if (requestId.current) {
            cancelAnimationFrame(requestId.current);
            requestId.current = null;
        }
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getCanvas: () => canvasNode,
            play,
            stop
        }
    }, [canvasNode])

    return (
        <Box className="webgl-container" {...props} ref={ref}>
            <Box /*className="canvas-preview"*/>
                <canvas ref={canvasRef} style={{ imageRendering: 'pixelated' }}>
                    <p>Sorry your browser does not support HTML5</p>
                </canvas>
            </Box>
        </Box>
    )
})