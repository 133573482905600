import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { userApi } from './userApi';

export const interactionsApi = createApi({
    reducerPath: 'interactionsApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/interactions`, credentials: 'include' }),
    tagTypes: ['Interactions', 'UserPointBalance', 'UserInteractionSettings', 'DepositPromotions'],
    endpoints: (builder) => ({
        createInteraction: builder.mutation({
            query: (args) => {
                let formData = new FormData();
                formData.append('interaction', new Blob([JSON.stringify(args.interaction)], { type: 'application/json' }));
                formData.append('interactionVideo', args.interactionVideo);
                return {
                    method: 'POST',
                    body: formData,
                    formData: true
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
        }),
        getInteractions: builder.query({
            query: (args) => {
                const { page, pageSize, term } = args;
                if (term) {
                    return {
                        url: '',
                        method: 'GET',
                        params: { term: term, page: page, pageSize: pageSize }
                    }
                }
                return {
                    url: '',
                    method: 'GET',
                    params: { page: page, pageSize: pageSize }
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['Interactions']
        }),
        sendInteraction: builder.mutation({
            query: (args) => {
                return {
                    url: `/${args.interactionId}/users/${args.recipient}`,
                    method: 'POST',
                    body: args
                }
            },
            async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
                try {
                    await cacheDataLoaded;
                    dispatch(userApi.util.invalidateTags(['UserPointBalance']));
                } catch {
                    console.log('error');
                }
                await cacheEntryRemoved;
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response
        }),
        getInteractionSettings: builder.query({
            query: (username) => `/${username}/settings`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['UserInteractionSettings']
        }),
        getDepositPromotions: builder.query({
            query: () => `/deposit-promotions`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['DepositPromotions']
        }),
    }),
})

export const { 
    useCreateInteractionMutation,
    useGetInteractionsQuery,
    useSendInteractionMutation,
    useGetInteractionSettingsQuery,
    useGetDepositPromotionsQuery
} = interactionsApi