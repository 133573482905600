import { Box, Button, Card, CardBody, CheckBox, Form, FormField, Heading, Image, RangeInput, Select, Spinner, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput } from "grommet"
import { Checkmark, Copy, Download, Update } from "grommet-icons"
import { useEffect, useState } from "react"
import { useGenerateNewOverlayTokenMutation, useGetCurrentUserQuery, useGetInteractionSettingsQuery, useUpdateInteractionSettingsMutation } from "../../api/userApi"
import { divide, multiply, round } from "mathjs"
import { formattedPrice } from "../../utils/priceUtils"
import { Trans, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { authenticationSelector } from "../../slices/authSlice";

const multiplierOptions = [
    { label: 'x1', value: 1 },
    { label: 'x2', value: 2 },
    { label: 'x3', value: 3 },
    { label: 'x4', value: 4 },
    { label: 'x5', value: 5 },
    { label: 'x6', value: 6 },
    { label: 'x7', value: 7 },
    { label: 'x8', value: 8 },
    { label: 'x9', value: 9 },
    { label: 'x10', value: 10 }
]

export const MemesSettings = () => {

    const [moderationSettings, setModerationSettings] = useState([
        { title: 'Rude humor', low: '✅', some: '✅', mid: '✅', more: '✅', high: '❌' },
        { title: 'Swear words', low: '✅', some: '✅', mid: '✅', more: '❌', high: '❌' }, 
        { title: 'Insults', low: '✅', some: '✅', mid: '❌', more: '❌', high: '❌' }, 
        { title: 'Violence', low: '✅', some: '✅', mid: '❌', more: '❌', high: '❌' }, 
        { title: 'Screamers', low: '✅', some: '❌', mid: '❌', more: '❌', high: '❌' }, 
    ]);

    const [selectedValue, setSelectedValue] = useState(0);

    const { t, ready } = useTranslation('memes-settings', {
		useSuspense: false,
	});
    const isAuthenticated = useSelector(authenticationSelector);
	const { data: currentUser, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, { skip: !isAuthenticated });
    const { data: interactionSettings, isSuccess: isInteractionSettingsSuccess } = useGetInteractionSettingsQuery();
    const [updateInteractionSettings, { isLoading: isUpdatingSettings }] = useUpdateInteractionSettingsMutation();
    const [generateNewOverlayToken, { isLoading: isGeneratingNewToken }] = useGenerateNewOverlayTokenMutation();

    const [donatePageUrl, setDonatePageUrl] = useState('');
    const [isInteractionsEnabled, setInteractionsEnabled] = useState();
    const [volume, setVolume] = useState();
    const [overlayUrl, setOverlayUrl] = useState();
    const [price, setPrice] = useState();
    const [priceMultiplier, setPriceMultiplier] = useState();

    const [donatePageUrlCopied, setDonatePageUrlCopied] = useState(false);
    const [urlCopied, setUrlCopied] = useState(false);

    useEffect(() => {
        if (isCurrentUserSuccess) {
            setDonatePageUrl(`${process.env.REACT_APP_FE_URL}/memes/${currentUser.username}`);
        }
    }, [currentUser, isCurrentUserSuccess])

    useEffect(() => {
        if (interactionSettings) {
            setInteractionsEnabled(interactionSettings.isEnabled);
            setVolume(interactionSettings.volume);
            setOverlayUrl(`${process.env.REACT_APP_FE_URL}/overlays/${interactionSettings.widgetToken}`);
            setPrice(formattedPrice(interactionSettings.price));
            setPriceMultiplier({ label: `x${~~interactionSettings?.priceMultiplier}`, value: interactionSettings?.priceMultiplier });
        }
    }, [interactionSettings])

    const handleInteractionEnable = (event) => {        
        const value = event.target.checked;
        updateInteractionSettings({ isEnabled: value });
    }

    const handleVolumeChange = () => {
        console.log('blur');
        
        updateInteractionSettings({ volume: volume });
    }

    const handleOverlayUrlCopy = () => {
        navigator.clipboard.writeText(overlayUrl)
        setUrlCopied(true);
        setTimeout(() => {
            setUrlCopied(false);
        }, 1000)
    }

    const handleDonatePageUrlCopy = () => {
        navigator.clipboard.writeText(donatePageUrl)
        setDonatePageUrlCopied(true);
        setTimeout(() => {
            setDonatePageUrlCopied(false);
        }, 1000)
    }

    const handleNewTokenGeneration = () => {
        generateNewOverlayToken();
    }

    const handlePriceChange = (event) => {
        const value = event.target.value;
        if (value === "" || /^-?\d+$/.test(value)) {
            setPrice(value);
        }
    }

    const handlePriceSave = () => {
        const body = {
            price: round(multiply(price, 100)),
            priceMultiplier: priceMultiplier.value
        }
        updateInteractionSettings(body);
    }

    const validatePrice = (value) => {
        if (Number(value) < 10 || Number(value) > 10000) {
            return { message: 'Price should be between 10 and 10000', status: 'error' }
        }
        return undefined;
    }

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready && isInteractionSettingsSuccess) {
        return (
            <Box direction="row" margin={{ top: 'medium' }}>
                <Card background="#222222" flex>
                    <CardBody pad="medium" direction="column" gap="medium">
                        <Box border={{ color: 'background-contrast', side: 'bottom', size: 'small' }}>
                            <Heading margin={{ top: 'none' }} level={2} size="medium" weight={'bold'}>
                                {t('title')}
                            </Heading>
                        </Box>
                        <Box direction="row-responsive" gap="medium">
                            <Box direction="column">
                                <Box>
                                    <Heading margin={{ top: 'none' }} level={3} size="medium" weight={'bold'}>
                                        {t('donatePageTitle')}
                                    </Heading>
                                </Box>
                                <Box direction="column" gap="xsmall" margin={{ top: 'none', bottom: 'medium' }}>
                                    <Text weight={500} size="14px">{t('donatePageLinkTitle')}</Text>
                                    <Box direction="row" border round="xsmall">
                                        <TextInput plain type="text" value={donatePageUrl}/>
                                        <Button icon={donatePageUrlCopied ? <Checkmark/> : <Copy/>} onClick={handleDonatePageUrlCopy} />
                                    </Box>
                                </Box>
                                <Box border={{ color: 'background-contrast', side: 'top', size: 'small' }}>
                                    <Heading level={3} size="medium" weight={'bold'}>
                                        {t('widgetSettingsTitle')}
                                    </Heading>
                                </Box>
                                <Box>
                                    {isInteractionsEnabled !== undefined && <CheckBox toggle label={t('enableMemesButton')} checked={isInteractionsEnabled} onChange={handleInteractionEnable} pad={'none'}/>}
                                    {isInteractionsEnabled === undefined && <CheckBox toggle label={t('enableMemesButton')} pad={'none'}/>}
                                </Box>
                                <Box direction="row" gap="small" align="center" margin={{ top: 'small' }}>
                                    <Text weight={500} size="14px">{t('volumeTitle')}</Text>
                                    {volume !== undefined && <RangeInput min={0} max={1} step={0.01} value={volume} onChange={(event) => setVolume(Number(event.target.value))} />}
                                    {volume === undefined && <RangeInput min={0} max={1} step={0.01} value={0} />}
                                    <Button label={t('volumeSaveButton')} secondary onClick={handleVolumeChange} busy={isUpdatingSettings} />
                                </Box>
                                <Box direction="column" gap="xsmall" margin={{ top: 'small' }}>
                                    <Text weight={500} size="14px">{t('widgetLinkTitle')}</Text>
                                    <Box direction="row" border round="xsmall">
                                        {overlayUrl && <TextInput plain type="text" value={overlayUrl}/>}
                                        {!overlayUrl && <TextInput plain type="text" value={'Loading...'}/>}
                                        <Button icon={urlCopied ? <Checkmark/> : <Copy/>} onClick={handleOverlayUrlCopy} />
                                        <Box flex="false">
                                            <Button icon={<Update/>} onClick={handleNewTokenGeneration} busy={isGeneratingNewToken} />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box direction="row" gap="small" margin={{ top: 'medium', bottom: 'medium' }}>
                                    <Button label="Test meme" secondary/>
                                    <Button label="Test fullscreen meme" secondary/>
                                </Box> */}
                                <Box margin={{ top: 'medium' }} border={{ color: 'background-contrast', side: 'top', size: 'small' }}>
                                    <Heading level={3} size="medium" weight={'bold'}>
                                        {t('priceSettingsTitle')}
                                    </Heading>
                                </Box>
                                <Box direction="column" gap="small">
                                    <Form validate="change" onSubmit={handlePriceSave}>
                                        <Box flex gap="small">
                                            <Box direction="column" gap="xsmall" flex>
                                                {price !== undefined && <FormField required={{ indicator: false }} validate={validatePrice} label={t('memePriceSettingsTitle')} name="price" info={<Text size="xsmall"><Trans i18nKey="memes-settings:memePriceSettingsInfo" values={{ price: price }} /></Text>}><TextInput name="price" type="text" placeholder={t('memePricePlaceholder')} value={price} onChange={handlePriceChange}/></FormField>}
                                                {price === undefined && <FormField required label={t('memePriceSettingsTitle')}><TextInput name="price" type="text" value={'Loading...'}/></FormField>}
                                            </Box>
                                            <Box direction="column" gap="xsmall" flex>
                                                {priceMultiplier && <FormField label={t('fullscreenMemePriceSettingsTitle')} name="priceMultiplier" info={<Text size="xsmall"><Trans i18nKey="memes-settings:fullscreenMemePriceSettingsInfo" values={{ price: price * priceMultiplier.value }} /></Text>}><Select value={priceMultiplier} options={multiplierOptions} labelKey={'label'} valueKey={'value'} onChange={(event) => setPriceMultiplier(event.value)} /></FormField>}
                                                {!priceMultiplier && <FormField label={t('fullscreenMemePriceSettingsTitle')}><TextInput type="text" value={'Loading...'} /></FormField>}
                                            </Box>
                                            <Button type="submit" label={t('priceSaveButton')} secondary busy={isUpdatingSettings} />
                                        </Box>
                                    </Form>
                                </Box>
                            </Box>
                            {/* <Box direction="column" fill>
                                <Box>
                                    <Heading margin={{ top: 'none' }} level={3} size="medium" weight={'bold'}>
                                        Moderation settings
                                    </Heading>
                                </Box>
                                <Box>
                                    <Text weight={500} size="14px">Memes moderation</Text>
                                    <RangeInput min={0} max={4} step={1} defaultValue={0} onChange={(event) => setSelectedValue(Number(event.target.value))}/>
                                </Box>
                                <Box margin={{ bottom: 'medium' }}>
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                <TableCell scope="col" border="bottom" size="small">
                                                    Title
                                                </TableCell>
                                                <TableCell scope="col" border="bottom" size="xxsmall">
                                                    None
                                                </TableCell>
                                                <TableCell scope="col" border="bottom" size="xxsmall">
                                                    Low
                                                </TableCell>
                                                <TableCell scope="col" border="bottom" size="xxsmall">
                                                    Mid
                                                </TableCell>
                                                <TableCell scope="col" border="bottom" size="xxsmall">
                                                    More
                                                </TableCell>
                                                <TableCell scope="col" border="bottom" size="xxsmall">
                                                    High
                                                </TableCell>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {moderationSettings.map(({ title, low, some, mid, more, high }) => (
                                                <TableRow>
                                                    <TableCell>
                                                        {title}
                                                    </TableCell>
                                                    <TableCell background={{ color: selectedValue === 0 ? 'brand' : '', opacity: 'medium' }}>
                                                        {low}
                                                    </TableCell>
                                                    <TableCell background={{ color: selectedValue === 1 ? 'brand' : '', opacity: 'medium' }}>
                                                        {some}
                                                    </TableCell>
                                                    <TableCell background={{ color: selectedValue === 2 ? 'brand' : '', opacity: 'medium' }}>
                                                        {mid}
                                                    </TableCell>
                                                    <TableCell background={{ color: selectedValue === 3 ? 'brand' : '', opacity: 'medium' }}>
                                                        {more}
                                                    </TableCell>
                                                    <TableCell background={{ color: selectedValue === 4 ? 'brand' : '', opacity: 'medium' }}>
                                                        {high}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Box border={{ color: 'background-contrast', side: 'top', size: 'small' }}>
                                    <Heading level={3} size="medium" weight={'bold'}>
                                        Meme page QR
                                    </Heading>
                                </Box>
                                <Box direction="column" gap="small" width={'50%'}>
                                    <Box round="small" overflow={'hidden'}>
                                        <Image src={`${process.env.PUBLIC_URL}/images/qr_preview.png`} />
                                    </Box>
                                    <Button label="Download" icon={<Download/>} secondary />
                                </Box>
                            </Box> */}
                        </Box>
                    </CardBody>
                </Card>
            </Box>
        )
    }
}