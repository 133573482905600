import { Box, Card, CardBody, Heading } from "grommet"
import { EventsTable } from "../components/events/EventsTable"
import { useGetEventRecordsQuery, useLazyGetEventRecordsQuery } from "../api/eventRecordsApi"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

const data = [
    { 
        id: 'qwerty1', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty2', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty3', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty4', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty5', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty6', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty7', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty8', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty9', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty10', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty11', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty12', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty13', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty14', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty15', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty16', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty17', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty18', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty19', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty20', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    // =========
    { 
        id: 'qwerty21', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty22', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty23', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty24', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty25', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty26', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty27', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty28', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty29', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty30', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty31', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty32', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty33', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty34', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty35', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    //
    { 
        id: 'qwerty36', 
        data: { 
            sender: 'Alan',
            // message: 'Hello world',
            type: 'INTERACTION',
            interactionTitle: 'This is a test title KEKW',
            interactionPreview: 'https://whatyouwish-previews.s3.eu-central-1.amazonaws.com/fc755da2-838d-4a13-a208-d8d91c7205c7/preview.png',
            creationTime: '2024-01-01T12:20:00'
        }, 
        // currencyAmount: 20
    },
    { 
        id: 'qwerty37', 
        data: { 
            sender: 'Bryan',
            // message: 'Hello world', 
            type: 'INTERACTIONS_POINTS_BUY',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 450,
        pointsAmount: 500
    },
    { 
        id: 'qwerty38', 
        data: { 
            sender: 'Chris', 
            message: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ', 
            type: 'AUCTION_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 20000
    },
    { 
        id: 'qwerty39', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'AUCTION_TWITCH_POINTS',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    },
    { 
        id: 'qwerty40', 
        data: { 
            sender: 'Eric', 
            message: 'Hello world', 
            type: 'ROULETTE_DONATE',
            creationTime: '2024-01-01T12:20:00'
        }, 
        currencyAmount: 80
    }
]



export const EventsPage = () => {

    const { t, ready } = useTranslation('dashboard', {
        useSuspense: false,
    });
    const [pagination, setPagination] = useState({ page: 0, pageSize: 30, sortBy: '' });
    const [total, setTotal] = useState(73);
    const [hasNext, setHasNext] = useState();
    const [eventsData, setEventsData] = useState([]);
    const [getEventRecords] = useLazyGetEventRecordsQuery();

    const fetchEventRecords = useCallback(() => {
        getEventRecords(pagination).then(response => {
            const responseData = response.data;
            setTotal(responseData?.pagination.totalElements);
            setHasNext(responseData?.pagination.hasNext);
            setEventsData(responseData?.events);
        })
    }, [])

    useEffect(() => {
        console.log(pagination);
        
        fetchEventRecords();
    }, [fetchEventRecords])

    const onMoreLoad = useCallback(() => {
        if (hasNext) {
            const newPagination = { ...pagination, ...{ page: pagination.page + 1 } };
            getEventRecords(newPagination).then(response => {
                const responseData = response.data;
                setTotal(responseData.pagination.totalElements);
                setHasNext(responseData.pagination.hasNext);
                setEventsData((prevData) => [ ...prevData, ...responseData.events ]);
                setPagination(newPagination);
            })
        }
    }, [hasNext, pagination])

    return (
        <Card fill>
            <CardBody gap="small">
                <Heading level={3} margin={'none'}>{t('eventsTitle')}</Heading>
                <Box>
                    <EventsTable data={eventsData} total={total} step={15} onMore={onMoreLoad} />
                </Box>
            </CardBody>
        </Card>
    )
}