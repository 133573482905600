import { Box, Text } from "grommet"


export const MemePreviewTitle = ({ meme, ...props }) => {

    return (
        <Box direction="column" fill justify="between" className="meme-title" pad={'small'} {...props}>
            {/* <Box pad={'xxsmall'} alignSelf="end" round="xsmall" background={'#026b10'} align="center">Fullscreen</Box> */}
            {meme.isFullscreen && (<Box pad={'xxsmall'} alignSelf="end" round="xsmall" background={'#026b10'} align="center">Fullscreen</Box>)}
            {meme.isSpot && (<Box pad={'xxsmall'} alignSelf="end" round="xsmall" background={'#bf7104'} align="center">Spot</Box>)}
            {!meme.isFullscreen && !meme.isSpot && (<Box pad={'xxsmall'} alignSelf="end" round="xsmall" background={'#4b17b3'} align="center">Mixed</Box>)}
            <Text weight={'bold'} size="medium" color={'black'} style={{ textShadow: '2px 0 #fff,-2px 0 #fff,0 2px #fff,0 -2px #fff,1px 1px #fff,-1px -1px #fff,1px -1px #fff,-1px 1px #fff,0 0 5px #fff' }}>{meme?.title}</Text>
        </Box>
    )
}