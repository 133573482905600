import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/configs`, credentials: 'include' }),
    tagTypes: ['Configs'],
    endpoints: (builder) => ({
        getConfigs: builder.query({
            query: () => '',
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            providesTags: ['Configs']
        }),
    }),
})

export const { useGetConfigsQuery } = configApi